import React from "react"
import { graphql } from "gatsby"
import parse from "html-react-parser"

import {
  Article,
  ArticleHeader,
  ArticleDate,
  ArticleSection,
  ArticleAuthorInfo
} from "../../styledComponents/article"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import RelatedQtArticles from "../../components/QuantumTouch/relatedQuantumTouchAricles"
import EventList from "../../components/EventList"
import {
  Section,
  SectionHeading
} from "../../styledComponents/section"
import { BackgroundColourBlock } from "../../styledComponents/overView"
import ShareTools from "../../components/socialLinks"
import { StyledBlockQuote } from "../../styledComponents/base"
import Icon from "../../components/icon"
import { ReactVideoPlayer } from "../../components/reactPlayer"

const Heading = ({ level = 1, location, children }) => (
  <SectionHeading as={`h${level}`} level={level} location={location}>
    {children}
  </SectionHeading>
)

const WhatIsQt = ({ data, location }) => {
  const pageTitle = data.allWpInformation.nodes[0].title
  const pageContent = data.allWpInformation.nodes[0].content
  
  const urlRegex = data.allWpInformation.nodes[0].uri.replace("information/%information_category%/", "quantum-touch/");
  const shareURL = `https://${process.env.GATSBY_DOMAIN_NAME}`+ urlRegex
  const image = {
    imageData: data.allWpInformation.nodes[0].featuredImage?.node?.localFile?.publicURL,
    alt: data.allWpInformation.nodes[0].featuredImage?.node?.alt || ``,
  }
  return (
    <Layout displayNavBorder location={location}>
      <SEO 
        title={pageTitle} 
        description="Quantum-Touch is a method of energy healing that uses very specific breathing techniques and body awareness exercises - to create a powerful, yet natural, field of healing energy."
        url="/quantum-touch/what-is-quantum-touch-energy-healing"
        image={image.imageData}
      />
      <Article itemScope itemType="http://schema.org/Article">
        <ArticleHeader>
          <h1 itemProp="headline">{pageTitle}</h1>
          <ArticleDate pubdate dateTime={data.allWpInformation.nodes[0].dateGmt}><span className="publishedon">Published on</span> {data.allWpInformation.nodes[0].date}</ArticleDate>
          <ArticleAuthorInfo className="author"><span>Published by Karina Grant</span> </ArticleAuthorInfo>
          <ShareTools shareTitle="Share this on" pageLocation={shareURL} postTitle={pageTitle}/>
        </ArticleHeader>

        {!!pageContent && (
          <ArticleSection itemProp="articleBody">
            {parse(pageContent)}

            <h2>Watch this video testimonial from a past participant</h2>
            <p>In this short video Clare talks about some of the highlights she has witnessed with Quantum-Touch. This includes blood pressure reduce, better breath output when doing “air flow tests” for the lungs and it is a part of her life as a nurse – especially through covid.</p>
            <p>Clare has been using the Quantum-Touch breath and techniques both personally and professionally.</p>

          <StyledBlockQuote>
            <ReactVideoPlayer
            videoURL="https://karinagrant.s3.eu-west-1.amazonaws.com/videos/testimonials/Claire-Green-NHS-Nurse.mp4" 
            title="Quantum-Touch Testimonials"
            placeholder="/images/clare-green-testimonial-placeholder.jpg"
            />
            <cite>Clare Green is a registered respiratory nurse working with patients in England’s NHS</cite>
          </StyledBlockQuote>

            <h2>Does giving Quantum-Touch drain the practitioner?</h2>
            <p>The practitioner will not become drained from doing the work. Most often, the practitioner feels uplifted as a result.</p>
            <blockquote><p>The workshop was unbelievably wonderful and so is Karina’s teaching. There are so many materials learnt that were easy to learn. I gained lots and lots. Made me more feel more energised and feel my aches and pains less. Feel allot happier too that I came to attend this workshop. Looking forward to the next one!</p>
              <footer><cite>Rebecca Tellias, London – Denham Unit – Ealing Hospital, NHS Trust – Registered nurse</cite></footer>
            </blockquote>
            <h2>A note about healing</h2>
            <p>Quantum-Touch does not claim to treat or heal specific conditions. Ultimately if the body can, it will heal itself and Quantum-Touch may accelerate that process.</p>
            <ShareTools shareTitle="Share this on" pageLocation={shareURL} postTitle={pageTitle}/>
          </ArticleSection>
        )}
        <RelatedQtArticles data={data.relatedArticles} /> 
      </Article>
    </Layout>
  )
}

export default WhatIsQt

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allWpInformation(filter: {tags: {nodes: {elemMatch: {slug: {eq: "quantum-touch"}}}}, slug: {eq: "what-is-quantum-touch-energy-healing"}}) {
      nodes {
        title
        excerpt
        content
        uri
        slug
        date(formatString: "MMMM DD, YYYY")
        dateGmt
        featuredImage {
          node {
            localFile {
              publicURL
            }
          }
        }
      }
    }


    relatedArticles : allWpInformation(filter: {tags: {nodes: {elemMatch: {slug: {eq: "quantum-touch"}}}}}) {
      nodes {
        title
        slug
        uri
      }
    }
  }
`


